import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Vacancy from "../components/Vacancy";
import Slider from "react-slick";
// import { Helmet } from 'react-helmet';
// import { useParams } from "react-router-dom";
// import { isEmpty } from "lodash";
// import moment from "moment";

// import Loader from "../components/Loader";

// import { sendNotification } from "../helpers/notification";

// import { PageContext } from "../context/page-context";

// moment.locale('ru');

const Home = (props) => {
  const [loading, setLoading] = React.useState(true);
  // const { uuid } = useParams();
  // const PageCunsumer = useContext(PageContext);

  useEffect(() => {
    setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const ArrowLeft = ({ currentSlide, slideCount, ...props }) => <i {...props} className="fas fa-sign-in-alt slick-prev slick-arrow prev"></i>;
  const ArrowRight = ({ currentSlide, slideCount, ...props }) => <i {...props} className="fas fa-sign-in-alt slick-next slick-arrow next"></i>;

  let settings = {
    dots: true,
    infinite: true,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {/* <Helmet>
        <title>Apex Group - Удобная P2P площадка</title>
        <meta name="description" content="Вопросы которые часто спрашивают новые пользователи на p2p площадке pfqymix.ru" />
      </Helmet> */}

      <Header/>

      <div className="offcanvas-menu">
        <span className="menu-close">
          <i className="fas fa-times"></i>
        </span>
        <form
          role="search"
          method="get"
          id="searchform"
          className="searchform"
          action="http://wordpress.zcube.in/xconsulta/"
        >
          <input
            type="text"
            name="s"
            id="search"
            // value=""
            placeholder="Search"
          />
          <button>
            <i className="fa fa-search"></i>
          </button>
        </form>

        <div id="cssmenu3" className="menu-one-page-menu-container">
          <ul id="menu-one-page-menu-1" className="menu">
            <li className="menu-item menu-item-type-custom menu-item-object-custom">
              <a href="index.html">Home</a>
            </li>
            <li className="menu-item menu-item-type-custom menu-item-object-custom">
              <a href="about.html">About Us</a>
            </li>
            <li className="menu-item menu-item-type-custom menu-item-object-custom">
              <a href="services.html">Services</a>
            </li>
            <li className="menu-item menu-item-type-custom menu-item-object-custom">
              <a href="pricing.html">Pricing </a>
            </li>
            <li className="menu-item menu-item-type-custom menu-item-object-custom">
              <a href="team.html">Team </a>
            </li>

            <li className="menu-item menu-item-type-custom menu-item-object-custom">
              <a href="projects.html">Cases Study</a>
            </li>
            <li className="menu-item menu-item-type-custom menu-item-object-custom">
              <a href="blog.html">Blog</a>
            </li>
            <li className="menu-item menu-item-type-custom menu-item-object-custom">
              <a href="contact.html">Contact</a>
            </li>
          </ul>
        </div>

        <div id="cssmenu2" className="menu-one-page-menu-container">
          <ul id="menu-one-page-menu-1" className="menu">
            <li className="menu-item menu-item-type-custom menu-item-object-custom">
              <a href="tel:+79595555474">
                <span>+7 959 5555 474</span>
              </a>
            </li>
            <li className="menu-item menu-item-type-custom menu-item-object-custom">
              <a href="email:lugansk@gosavtopolis.ru">
                <span>lugansk@gosavtopolis.ru</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="offcanvas-overly"></div>

      <main>
        <section id="home" className="slider-area fix p-relative">
          <div className="">
            <div
              className="single-slider slider-bg d-flex pt-200 pb-5 pb-sm-50"
              style={{
                background: `url(${require("../images/bg/header-bg.png")})`,
                backgroundSize: "cover",
              }}
            >
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-5">
                    <div className="header-img mt-100 animated pulse  sm-text-center ">
                      <img
                        src={require("../images/car.png")}
                        alt="header-img"
                        className="w-sm-img"
                      />
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="slider-content s-slider-content mt-170 animated fadeInUp">
                      <h5>Страховое агентство</h5>
                      <h2 className="">Все виды страхования:</h2>
                      <hr />
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          <h3 className="mb-3">
                            <i className="fa fa-arrow-right text-success mr-2"></i>{" "}
                            ОСАГО
                          </h3>
                          <h3 className="mb-3">
                            <i className="fa fa-arrow-right text-success mr-2"></i>{" "}
                            КАСКО
                          </h3>
                          <h3 className="mb-3 text-uppercase">
                            <i className="fa fa-arrow-right text-success mr-2"></i>{" "}
                            Имущество
                          </h3>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <h3 className="mb-3 text-uppercase">
                            <i className="fa fa-arrow-right text-success mr-2"></i>{" "}
                            Здоровье
                          </h3>
                          <h3 className="mb-3 text-uppercase">
                            <i className="fa fa-arrow-right text-success mr-2"></i>{" "}
                            Туризм
                          </h3>
                          <h3 className="mb-3 text-uppercase">
                            <i className="fa fa-arrow-right text-success mr-2"></i>{" "}
                            Ипотека
                          </h3>
                        </div>
                      </div>
                      <h3 className="mb-3 mt-3 text-uppercase">
                        <i className="fa fa-arrow-right text-success mr-2"></i>{" "}
                        И другие виды страхования
                      </h3>

                      <div className="slider-btn mt-40 mb-100 sm-text-center mb-sm-50">
                        <a href="/osago" className="btn ss-btn lg">
                        Расчет ОСАГО{" "}
                          <i className="fal fa-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="about" className="about-area about-p pt-100 p-relative p-sm-none ">
          <div className="container">
            <div className="about-title second-atitle pb-20 pt-sm-50 animated fadeInUp">
              <h5>
                <div className="line">
                  {" "}
                  <img
                    src={require("../images/bg/circle_right.png")}
                    alt="circle_right"
                  />
                </div>{" "}
                некоторые факты о нас
              </h5>
              <h3 className="pt-sm-50">Мы Российская компания с 19 летним опытом продаж</h3>
            </div>

            <div className="row align-items-center">
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="about-content s-about-content">
                  <ul className="ab-ul">
                    <li>
                      <div className="icon">
                        <i className="fal fa-check"></i>
                      </div>
                      <div className="text pt-10">
                        Госавтополис, это компания под крылом которой есть
                        множество других страховых компаний. Если клиенту
                        отказали в Астроволге, он может прийти к нам и мы
                        подберем для него страховку на других сайтах
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check"></i>
                      </div>
                      <div className="text pt-10">
                        Точный расчет и низкая цена 🤑
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check"></i>
                      </div>
                      <div className="text pt-10">
                        Действие на всей территории рф, крым, лнр, днр, херсон и
                        запорожье 🤝
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check"></i>
                      </div>
                      <div className="text pt-10">
                        Несколько офисов расположенных в удобных частях города и
                        области (доступно для клиента чтоб не кататься по всему
                        городу или ехать с области)
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check"></i>
                      </div>
                      <div className="text pt-10">Работаем в субботу ⏱</div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check"></i>
                      </div>
                      <div className="text pt-10">
                        Если вы находитесь не в лнр, то можно обратится в любой
                        офис ГосАвтоПолис и получить концультацию или решить
                        какие то вопросы. 👌
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check"></i>
                      </div>
                      <div className="text pt-10">
                        Услуга отложенный платеж(выдаем квитанцию об оплате) 📃
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check"></i>
                      </div>
                      <div className="text pt-10">Акции, бонусы, скидки 🔥</div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check"></i>
                      </div>
                      <div className="text pt-10">
                        Все виды страхования под любого клиента 🔥
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check"></i>
                      </div>
                      <div className="text pt-10">
                        Высокий професионализм 🤝
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-5 col-md-12 col-sm-12">
                <img
                  src={require("../images/gosautopolis.png")}
                  className=""
                  alt=""
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 p-4 text-center">
                <img
                  src={require("../images/features/21827.png")}
                  className="w-75"
                  alt=""
                />
              </div>

              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="about-content s-about-content">
                  <ul className="ab-ul">
                    <li>
                      <div className="icon">
                        <i className="fal fa-check"></i>
                      </div>
                      <div className="text pt-10">
                        Если вы находитесь не в лнр, то можно обратится в любой
                        офис ГосАвтоПолис и получить концультацию или решить
                        какие то вопросы. 👌
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check"></i>
                      </div>
                      <div className="text pt-10">
                        Услуга отложенный платеж(выдаем квитанцию об оплате) 📃
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check"></i>
                      </div>
                      <div className="text pt-10">Акции, бонусы, скидки 🔥</div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check"></i>
                      </div>
                      <div className="text pt-10">
                        Все виды страхования под любого клиента 🔥
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fal fa-check"></i>
                      </div>
                      <div className="text pt-10">
                        Высокий професионализм 🤝
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          className="counter-area pt-100 pb-120 p-relative pt-sm-50 pb-sm-50"
          style={{ backgroundColor: "#f7fafd" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="section-title center-align mb-50 text-center ">
                  <h5>
                    <span className="line2 ">
                      {" "}
                      <img
                        src={require("../images/bg/circle_right.png")}
                        alt="circle_right"
                      />
                    </span>{" "}
                    Немного цифр{" "}
                    <span className="line3">
                      {" "}
                      <img
                        src={require("../images/bg/circle_left.png")}
                        alt="circle_left"
                      />
                    </span>
                  </h5>
                  <h3>Достижения в числах - Страхование смыслов</h3>
                </div>
              </div>
            </div>
            <div className="row counter-bg p-relative">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="single-counter wow fadeInUp animated">
                  <div className="icon">
                    <img
                      src={require("../images/icon/crv-icon3.png")}
                      alt="img"
                    />
                  </div>

                  <div className="counter p-relative">
                    <span className="count">10 лет</span>
                    <p>На рынке</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="single-counter wow fadeInUp animated">
                  <div className="icon">
                    <img
                      src={require("../images/icon/protection.png")}
                      alt="img"
                      width="64px"
                    />
                  </div>
                  <div className="counter p-relative">
                    <span className="count">9000</span>
                    <small>+</small> <p>Застраховано авто</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="single-counter wow fadeInUp animated">
                  <div className="icon">
                    <img
                      src={require("../images/icon/office2.png")}
                      alt="img"
                    />
                  </div>

                  <div className="counter p-relative">
                    <span className="count">120</span>
                    <small>+</small>
                    <p>Офисов в городах</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="single-counter wow fadeInUp animated">
                  <div className="icon">
                    <img
                      src={require("../images/icon/crv-icon2.png")}
                      alt="img"
                    />
                  </div>

                  <div className="counter p-relative">
                    <span className="count">100</span>
                    <small>+</small>
                    <p>пакетов страхования</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section
          className="pb-100 pb-sm-50 offices"
          style={{ backgroundColor: "#ffff" }}
          id="offices"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title center-align mb-50 text-center">
                  <h3 className="h2 font-weight-bold mt-4">Наши отделения 😎:</h3>
                </div>
              </div>
              <div className="col-md-6 mb-4 item col-12">
                <h5>
                  ⚡️ г. Луганск:  <br/>
                  ул. Ленина 236/30 (район бывшего Артемовского военкомата) <br/>
                  Тел: <a href="tel:+79595555474">+7(959) 5555-474</a> (Telegram,WhatsApp) <br /> или короткий 474
                </h5>
              </div>
              <div className="col-md-6 mb-4 item col-12">
                <h5>
                ⚡️ г. Луганск: <br />
                кв. Комарова 16 <br />
                Тел: <a href="tel:+79595555474">+7(959) 5555-474</a> (Telegram,WhatsApp) <br /> или короткий 474
                </h5>
              </div>
              <div className="col-md-6 mb-4 item col-12">
                <h5>
                ⚡️ г. Луганск: <br />
                ул. Линева 68  <br />
                Тел: <a href="tel:+79591390568">+7(959)139-05-68</a> и <a href="tel:+79592002147">+7(959)200-21-47</a>
                </h5>
              </div>
              <div className="col-md-6 mb-4 item col-12">
                <h5>
                ⚡️ г. Алчевск: <br />
                ул. Московская, дом 4 <br />
                Тел: <a href="tel:+79595051577">+7 959 505 1577</a>
                </h5>
              </div>
              <div className="col-md-6 mb-4 item col-12">
                <h5>
                ⚡️ г. Алчевск: <br />
                проспект Ленина 28. <br />
                Тел: <a href="tel:+79591300224">+7(959)130-02-24</a>
                </h5>
              </div>
              <div className="col-md-6 mb-4 item col-12">
                <h5>
                ⚡️ г. Лутугино: <br />
                ул. Ленина 120А Универмаг (2-ой этаж) <br />
                Тел: <a href="tel:+79592257194">+7(959)225-71-94</a> и <a href="tel:+79592041109">+7(959)204-11-09</a>
                </h5>
              </div>
              <div className="col-md-6 mb-4 item col-12">
                <h5>
                ⚡️ г. Старобельск <br />
                ул. Трудовая 23/3 <br />
                Тел: <a href="tel:+79595199577">+7(959)51-99-577</a> и <a href="tel:+79595199573">+7(959)51-99-573</a>
                </h5>
              </div>
              <div className="col-md-6 mb-4 item col-12">
                <h5>
                ⚡️ пгт. Макровка <br />
                ул. Ленина д.5/2 <br />
                Тел: <a href="tel:+79595215442">+7(959)521-54-42</a>
                </h5>
              </div>
              <div className="col-md-6 mb-4 item col-12">
                <h5>
                ⚡️ г. Счастье <br />
                ул. Донецкая, д.123 <br />
                тел. <a href="tel:+79595810110">+7(959)58-10-110</a>
                </h5>
              </div>
              <div className="col-md-6 mb-4 item col-12 mx-auto">
                <h5>
                ⚡️ пгт. Станица Луганская <br />
                ТЦ "Квадрат" <br />
                Тел. <a href="tel:+79595555474">+7(959) 5555-474</a> (Telegram,WhatsApp) <br /> или короткий 474
                </h5>
              </div>
            </div>
            <h4 className="mb-4">Так же наши агенты есть в городах:</h4>
            <div className="row">
              <div className="col-md-6 mb-4 pb-3 item col-12">
                <h5>
                ⚡️ пгт. Белокуракино <a href="tel:+79595189093">+7(959)518-90-93</a> <br/>
                ⚡️ г. Алчевск <a href="tel:+79591538302">+7(959)153-83-02</a> и <a href="tel:+79591003317">+7(959)100-33-17</a> <br/>
                ⚡️ г. Перевальск <a href="tel:+79591538302">+7(959)153-83-02</a> и <a href="tel:+79591003317">+7(959)100-33-17</a> <br/>
                ⚡️ г. Краснодон <a href="tel:+79591121870">+7(959)112-18-70</a> <br/>
                ⚡️ г. Красный Луч <a href="tel:+79595555474">+7(959)5555-474</a> или 474 <br/>
                </h5>
              </div>
              <div className="col-md-6 mb-4 pb-3 item col-12">
                <h5>
                ⚡️ г. Антрацит <a href="tel:+79595555474">+7(959)5555-474</a> или 474 <br/>
                ⚡️ г. Молодогвардейск <a href="tel:+79595555474">+7(959)5555-474</a> или 474 <br/>
                ⚡️ г. Свердловск <a href="tel:+79595555474">+7(959)5555-474</a> или 474 <br/>
                ⚡️ г. Стаханов <a href="tel:+79595555474">+7(959)5555-474</a> или 474 <br/>
                ⚡️ п. Сватово <a href="tel:+79595555474">+7(959)5555-474</a> или 474 <br/>
                </h5>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-50">
          <div className="container rozsrochka">
            <div className="row">
              <div className="col-md-7">
                <h2 className="mb-4">
                  Функция <span>"Отсроченный платеж"</span> для ОСАГО
                </h2>
                <h5 className="mb-4 text-dark">
                  Чтоб не терять время Вы можете оформить ОСАГО сейчас! <br />{" "}
                  Но действовать оно начнет 01.01.2024 года 🔥
                </h5>
                <hr className="mb-3" />
                <h4 className="mb-3">То есть:</h4>
                <div className="row">
                  <div className="col-md-4">
                    <div className="icon-box">
                      <div className="icon">🤫</div>
                      <p>
                        <strong className="text-success">1.</strong> Вам не
                        нужно переплачивать, потому что в момент ажиотажа будет
                        дороже.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="icon-box">
                      <div className="icon">🤔</div>
                      <p>
                        <strong className="text-success">2.</strong> Не нужно
                        помнить, что нужно сделать страховку.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="icon-box">
                      <div className="icon">😎</div>
                      <p>
                        <strong className="text-success">3.</strong> Не нужно
                        стоять в очередях.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 pt-5">
                <img src={require("../images/rozsrochka.png")} alt="" />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="text-center">
              <a href="/osago" className="btn ss-btn">
                Расчет ОСАГО{" "}
                <i className="fal fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </section>

        <section className="pb-10">
          <div className="container">
            <div className="row mt-5">
              <div className="col-12 col-md-4">
                <img className="" src={require("../images/navigator.png")} alt="" />
              </div>
              <div className="col-12 col-md-8">
                <h4 className="mt-4 text-maps">
                    <span className="text-theme">Страховой полис действует на всей территории Российской Федерации.</span> А так же на присоединенные территории субъектов: ДНР, ЛНР, Херсонская область, Запорожская область, входящие в состав Российской Федерации, в границах контролируемых Российской Федерацией на момент страхового случая.
                </h4>
              </div>
            </div>
          </div>
        </section>

        <Vacancy/>

        <section
          className="testimonial-area2 pt-100 pb-100 pt-sm-50 pb-sm-50"
          style={{ backgroundColor: "#ffff" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title center-align mb-50 text-center">
                  <h5>
                    <span className="line2">
                      {" "}
                      <img
                        src={require("../images/bg/circle_right.png")}
                        alt="circle_right"
                      />
                    </span>{" "}
                    Простые слова, искренние эмоции – это то, что делает нашу работу особенной и ценной.
                    <span className="line3">
                      {" "}
                      <img
                        src={require("../images/bg/circle_left.png")}
                        alt="circle_left"
                      />
                    </span>
                  </h5>
                  <h3 className="h2 font-weight-bold mt-4">Что говорят о нас наши клиенты</h3>
                </div>
              </div>

              <div className="col-lg-12">
                <Slider {...settings} className="testimonial-active2">
                  <div className="single-testimonial">
                    <div className="row">
                      <div className="col-md-2 col-6 offset-3">
                        <div className="t-user">
                          <img
                            src={require("../images/testimonial/man.png")}
                            alt="img"
                          />
                        </div>
                      </div>
                      <div className="col-md-10 col-12">
                        <p>
                          Госавтополис действительно заботится о своих клиентах.
                          Их широкий спектр услуг и внимательное отношение к
                          каждому клиенту – это именно то, что я искал.
                        </p>
                        <div className="testi-author">
                          <div className="ta-info">
                            <h6>Дмитрий П.</h6>
                            <span>Наш клиент</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="qt-img">
                      <img
                        src={require("../images/icon/blockquote_d2.png")}
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="single-testimonial">
                    <div className="row">
                      <div className="col-md-2 col-6">
                        <div className="t-user">
                          <img
                            src={require("../images/testimonial/man.png")}
                            alt="img"
                          />
                        </div>
                      </div>
                      <div className="col-md-10 col-12">
                        <p>
                          Сотрудничество с Госавтополис – это настоящий
                          профессиональный опыт. Они всегда готовы помочь и
                          предоставить оптимальное решение для любой ситуации.
                        </p>
                        <div className="testi-author">
                          <div className="ta-info">
                            <h6>Алексей Г.</h6>
                            <span>Наш клиент</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="qt-img">
                      <img
                        src={require("../images/icon/blockquote_d2.png")}
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="single-testimonial">
                    <div className="row">
                      <div className="col-md-2 col-6">
                        <div className="t-user">
                          <img
                            src={require("../images/testimonial/woman.png")}
                            alt="img"
                          />
                        </div>
                      </div>
                      <div className="col-md-10 col-12">
                        <p>
                          Госавтополис – это надежный партнер для меня и моей
                          семьи. Их специалисты всегда готовы проконсультировать
                          и подобрать наилучшее решение.
                        </p>
                        <div className="testi-author">
                          <div className="ta-info">
                            <h6>Елена Т.</h6>
                            <span>Наш клиент</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="qt-img">
                      <img
                        src={require("../images/icon/blockquote_d2.png")}
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="single-testimonial">
                    <div className="row">
                      <div className="col-md-2 col-6">
                        <div className="t-user">
                          <img
                            src={require("../images/testimonial/man.png")}
                            alt="img"
                          />
                        </div>
                      </div>
                      <div className="col-md-10 col-12">
                        <p>
                          Как водитель, мне важно чувствовать, что меня
                          поддерживают. Спасибо Госавтополис за надежное
                          страхование.
                        </p>
                        <div className="testi-author">
                          <div className="ta-info">
                            <h6>Андрей Романович</h6>
                            <span>Наш клиент</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="qt-img">
                      <img
                        src={require("../images/icon/blockquote_d2.png")}
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="single-testimonial">
                    <div className="row">
                      <div className="col-md-2 col-6">
                        <div className="t-user">
                          <img
                            src={require("../images/testimonial/man.png")}
                            alt="img"
                          />
                        </div>
                      </div>
                      <div className="col-md-10 col-12">
                        <p>
                          Я доволен вашими услугами. С Госавтополисом я не боюсь
                          неприятностей на дороге.
                        </p>
                        <div className="testi-author">
                          <div className="ta-info">
                            <h6>Иван С.</h6>
                            <span>Наш клиент</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="qt-img">
                      <img
                        src={require("../images/icon/blockquote_d2.png")}
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="single-testimonial">
                    <div className="row">
                      <div className="col-md-2 col-6">
                        <div className="t-user">
                          <img
                            src={require("../images/testimonial/woman.png")}
                            alt="img"
                          />
                        </div>
                      </div>
                      <div className="col-md-10 col-12">
                        <p>
                          Благодаря Госавтополис я чувствую, что моя
                          безопасность на первом месте. Это компания, которая
                          понимает важность качественного страхования.
                        </p>
                        <div className="testi-author">
                          <div className="ta-info">
                            <h6>Ольга М.</h6>
                            <span>Наш клиент</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="qt-img">
                      <img
                        src={require("../images/icon/blockquote_d2.png")}
                        alt="img"
                      />
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>

        
      </main>
      
      <Footer/>

    </>
  );
};

export default Home;
